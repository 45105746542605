import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'

export default function NotFoundPage() {
  return (
    <Layout>
      <h1 className="text-5xl leading-8 mb-5">Oh No...</h1>
      <p className="mb-2 text-xl">
        We can't find the page that you are looking for.
      </p>

      <Link className="mb-2 text-xl" to="/">
        Back to home page?
      </Link>
    </Layout>
  )
}
